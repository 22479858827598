.App {
    text-align: center;
}

.App-photo {
    display: flex;
    height: auto;
    width: auto;
    min-height: 10vh;
    max-height: 40vh;
    pointer-events: none;
    border-radius: 24px;
}

.App-logo {
    display: flex;
    float: right;
    border-radius: 4px;
    padding: 5px;
    height: auto;
    width: auto;
    min-height: 50px;
    max-height: 7vh;
    right: 0px;
}

.App-header {
    background-color: #242424;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #F4F3EE;
}

.App-header-links {
    flex-direction: row;
}

a:link {
  color: #F4F3EE;
  background-color: transparent;
  text-decoration: underline;
}

a:visited {
  color: #F4F3EE;
  background-color: transparent;
  text-decoration: underline;
}

a:hover {
  color: black;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #463F3Ao;
  background-color: transparent;
  text-decoration: underline;
}
