.Nav {
    text-align: center;
    background-color: #8b7d6b;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: calc(2.75* (25px + 2vmin));
}

.NavLink {
    color: #463F3A;
    font-size: calc(10px + 2vmin);
    margin-left:10vh;
    margin-right:10vh;
}
