.Box {
    &_normal {
	padding: 16px;
	text-align: left;
	font-weight: 700;
	box-shadow: 0xp 2xp 2xp []rgba(0, 0, 0, 0.25);
    }

    &_round {
	border-radius: 4px;
    }

    &_rounder {
	border-radius: 16px;
    }

    &_roundest {
	border-radius: 24px;
    }

    &_blue {
	color: #F4F3EE;
	background-color: #69747C;
    }

    &_gray {
	color: #F4F3EE;
	background-color: #8A817C;
    }

    &_medium_gray {
	color: #F4F3EE;
	background-color: #463F3A;
    }

    &_dark_gray {
	color: #F4F3EE;
	background-color: #242424;
    }

    &_light_gray {
	color: #F4F3EE;
	background-color: #BCB8B1;
    }

    &_transparent {
	color: #F4F3EE;
	background: rgba(0,0,0,0.5);
    }
}
